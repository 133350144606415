<template>
    <!--    <div class="inner-container">-->
    <!--        <div class="section-title budget"><span>Формирование лимитов расходов</span></div>-->
    <b-row>
        <b-col cols="12" class="p-0">
            <c-budg-head ref="budgetHeader" @chgData="chgData" :typeReq="false" :guReq="false" nameYear="Прогнозный год"></c-budg-head>

            <b-card class="table-container">
                <b-table
                    :fields="tableFields"
                    :items="budgetFactPlanArr"
                    responsive="true"
                    bordered
                    head-variant="light"
                    sticky-header
                    no-border-collapse
                >
                    <template #head(code)="scope">
                        <div>{{ tableFields[0].label }}</div>
                    </template>
                    <template #head(title)="scope">
                        <div>{{ tableFields[1].label }}</div>
                    </template>
                    <template #top-row="data">
                        <td></td>
                        <td class="text-left"><b>{{ totalField[0].label }}</b></td>
                        <td class="text-right"><b>{{ $n(factSum, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(planSum, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(oneTimeExpenses, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(refinedPlan, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(forecastYearPlusOne, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(expensesInExcessOfParameters, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(percentOfGrowth, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(twoYearPlusForecast, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(twoYearPlusPercent, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(threeYearPlusForecast, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(threeYearPlusPercent, 'decimal') }}</b></td>
                    </template>
                    <template #cell(spf)="data">
                        <div>
                            {{ data.item.spf }}
                        </div>
                    </template>
                    <template #cell(title)="data">
                        <div>
                            {{ data.item.title }}
                        </div>
                    </template>
                    <template #cell(factSum)="data">
                        <div class="text-right">
                            {{ data.item.factSum }}
                        </div>
                    </template>
                    <template #cell(planSum)="data">
                        <div class="text-right">
                            {{ data.item.planSum}}
                        </div>
                    </template>
                    <template #cell(oneTimeExpenses)="data">
                        <b-form-input
                            class="text-right"
                            :value="parseFloat(data.item.oneTimeExpenses).toFixed(2)"
                            @change="v => data.item.oneTimeExpenses = v"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="inputFixed(data.item, 'oneTimeExpenses', data.item.oneTimeExpenses, 2)">
                        </b-form-input>
                    </template>
                    <template #cell(refinedPlan)="data">
                        <div class="text-right">
                            {{ data.item.refinedPlan }}
                        </div>
                    </template>
                    <template #cell(forecastYearPlusOne)="data">
                        <b-form-input
                            class="text-right"
                            :value="parseFloat(data.item.forecastYearPlusOne).toFixed(2)"
                            @change="val => changeCellForecast(data.item, val)"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="inputFixed(data.item, 'forecastYearPlusOne', data.item.forecastYearPlusOne, 2)">
                        </b-form-input>
                    </template>
                    <template #cell(separateSpecific)="data">
                        <b-form-input
                            :value="parseFloat(data.item.separateSpecific).toFixed(2)"
                            type="hidden"
                        ></b-form-input>
                    </template>
                    <template #cell(expensesInExcessOfParameters)="data">
                        <b-form-input
                            class="text-right"
                            :value="parseFloat(data.item.expensesInExcessOfParameters).toFixed(2)"
                            @change="v => data.item.expensesInExcessOfParameters = v"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="inputFixed(data.item, 'expensesInExcessOfParameters', data.item.expensesInExcessOfParameters, 2)">
                        </b-form-input>
                    </template>
                    <template #cell(percentOfGrowth)="data">
                        <div class="text-right">
                            {{ data.item.percentOfGrowth }}
                        </div>
                    </template>
                    <template #cell(twoYearPlusForecast)="data">
                        <b-form-input
                            class="text-right"
                            :value="parseFloat(data.item.twoYearPlusForecast).toFixed(2)"
                            @change="val => changeCellForecastTwo(data.item, val)"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="inputFixed(data.item, 'twoYearPlusForecast', data.item.twoYearPlusForecast, 2)">
                        </b-form-input>
                    </template>
                    <template #cell(twoYearPlusPercent)="data">
                        <div class="text-right">
                            {{ data.item.twoYearPlusPercent }}
                        </div>
                    </template>
                    <template #cell(threeYearPlusForecast)="data">
                        <b-form-input
                            class="text-right"
                            :value="parseFloat(data.item.threeYearPlusForecast).toFixed(2)"
                            @change="v => data.item.threeYearPlusForecast = v"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="inputFixed(data.item, 'threeYearPlusForecast', data.item.threeYearPlusForecast, 2)">
                        </b-form-input>
                    </template>
                    <template #cell(threeYearPlusPercent)="data">
                        <div class="text-right">
                            {{ data.item.threeYearPlusPercent }}
                        </div>
                    </template>
                </b-table>
                <div class="table-footer">
                    <div class="table-buttons">
                        <b-button variant="success" @click="saveDataToDB">Сохранить</b-button>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
    <!--    </div>-->
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CBudgHeadReq from '@/modules/budget-request/budget-header.vue';
import store from '../../services/store';

export default {
    name: 'budget-limits',
    components: {
        'c-budg-head': CBudgHeadReq
    },
    data() {
        return {
            totalField: [
                {
                    key: 'total',
                    label: 'Всего затрат'
                }
            ],
            tableFields: [
                {
                    key: 'spf',
                    label: 'Код'
                },
                {
                    key: 'title',
                    label: 'Наименование'
                },
                {
                    key: 'factSum',
                    label: 'Исполнение за год'
                },
                {
                    key: 'planSum',
                    label: 'Уточненный план на г. (без РБ)'
                },
                {
                    key: 'oneTimeExpenses',
                    label: 'в т.ч. разовые расходы'
                },
                {
                    key: 'refinedPlan',
                    label: 'Уточненный план на Прогнозный год (без учета разовых расходов)'
                },
                {
                    key: 'forecastYearPlusOne',
                    label: 'Прогноз на год'
                },
                {
                    key: 'expensesInExcessOfParameters',
                    label: 'в т.ч. расходы, учтенные сверх параметров'
                },
                {
                    key: 'percentOfGrowth',
                    label: '% роста'
                },
                {
                    key: 'twoYearPlusForecast',
                    label: 'Прогноз на год'
                },
                {
                    key: 'twoYearPlusPercent',
                    label: '% роста год'
                },
                {
                    key: 'threeYearPlusForecast',
                    label: 'Прогноз на год'
                },
                {
                    key: 'threeYearPlusPercent',
                    label: '% роста год'
                }
            ],
            tempAr: [   // -----временные данные, которые нужно будет удалить и добавить в таблицу справочника 'budget_separate_specifics'
                {
                    id: '650471',
                    key: '',
                    indicator: 'Годовая фактическая потребность на выплату заработной платы',
                    code: 'annwage',
                    kato: '550000000'
                },
                {
                    id: '650476',
                    key: 'tariffutilit',
                    indicator: 'Рост тарифов по коммунальным услугам',
                    code: '',
                    kato: '550000000'
                }
            ],
            inflationIndex: '',     // -------переменная для хранения индекса инфляции на прогнозный год
            separateSpecific: [],  // --------Глобальный массив для хранения исключений по отдельным спецификам
            budgetFactPlanArr: [],  // ------основной массив
            compareFactPlanArr: [], // ------Массив для сравнения значений
            budgetLimitForm: [],
            factArrList: [],
            planArrList: [],
            uniqSpf: [],
            executionForMinusTwoForecastYears: [],
            curHeader: null,
            header: null,
            chosenYear: null,
            spfArrFact: [],
            spfArrFin: [],
            year: null,
            obl: null,
            region: null,
            abp: null,
            prg: null,
            ppr: null,
            ga: null,
            editing: false,
            firstFactPlanMap: new Map(),
            firstFactPlanMapSec: new Map(),
            firstFactPlanObject: {},
            leavePage: false,
            prognozOne: false,
            prognozTwo: false,
            prognozThree: false,
            executionTotal: null,
            firstFactPlanArray: []
        };
    },
    async mounted() {
        await this.getObl();
        await this.getListOfSpecifics();
        await this.getIndexOfInflation();
        this.tableFields[0].label = 'Код';
        this.tableFields[1].label = 'Наименование';
        // console.log('this.$refs.budgetlimits.beforeRouteLeave: ' + this.$refs.budgetlimits.beforeRouteLeave);    //---Нужно будет попробовать создать метод и передать его параметры на beforeRouteLeave на стороне Жанат
    },
    // ----Метод определяющий, переход по другую ссылку-----//
    beforeRouteLeave(to, from, next) {
        const res = this.comparingData();
        const page = this.leavePage;
        // console.log('page: ' + page);
        // console.log('res: ' + res.length);
        // if (res.length > 0) {
        if (page) {
            this.$bvModal.msgBoxConfirm(
                'Данные не сохранены. Вы действительно хотите покинуть страницу?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    next(value);
                    // if (value === true) {
                    //     this.firstFactPlanMap.clear();
                    // }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка валидации наличия не сохраненных данных', error.toString());
                });
        } else {
            next();
        }
    },
    methods: {
        chgData(data) { // ----Извлекаются параметры из меню фильтрации
            if (data !== null) {
                this.curHeader = data;
                if ((this.curHeader !== null)
                    && (this.curHeader.dataType !== null)
                    && (this.curHeader.year !== null)
                    && (this.curHeader.gr !== null)
                    && (this.curHeader.gu !== null)
                    && (this.curHeader.prg !== null)) {
                    this.budgetFactPlanArr = [];
                    this.header = {
                        year: this.curHeader.year,
                        data_type: this.curHeader.dataType.code,
                        abp: this.curHeader.abp.abp,
                        prg: this.curHeader.prg.prg,
                        ppr: (this.curHeader.pgr === null ? null : this.curHeader.pgr.ppr),
                        ga: this.curHeader.gu.code
                    };
                    this.chosenYear = this.header.year;
                    // console.log('ga: ' + this.header.ga);
                    this.getFieldsOfFactAndFin(this.header);
                    // console.log('!!!chgData', data);
                    // console.log('show header... year: ' + this.header.year + ' data_type: ' + this.header.data_type + ' abp: ' + this.header.abp + ' prg: ' + this.header.prg + ' ppr: ' + this.header.ppr + ' ga: ' + this.header.ga);
                }
            }
        },
        async getListOfSpecifics() {    // ----Извлекается справочник исключений отдельной специфики----//
            const response = await fetch('/api-py/budget_separate_specifics/');
            const items = await response.json();
            this.separateSpecific = items;
            // console.log(JSON.stringify('this.separateSpecific: ' + this.separateSpecific));
            // let spf = '';
            // for (let i = 0; i < items.length; i++) {
            //     spf = items[i].spf;
            // console.log('spf: ' + spf + ' dictIndicatorId: ' + items[i].dictIndicatorId + ' region_id: ' + items[i].region_id + ' kato: ' + items[i].kato);
            // }
            return this.separateSpecific;
        },
        async getIndexOfInflation() {   // ---Извлекается справочник индекса инфляции на прогнозный год---//
            const response = await fetch('/api-py/budget_inflation_index/');
            const items = await response.json();
            this.inflationIndex = items;
            // let inflation = '';
            // for (let i = 0; i < items.length; i++) {
            //     inflation = items[i].dictIndicatorId;
            //     console.log('inflation: ' + inflation + ' region_id: ' + items[i].region_id + ' kato: ' + items[i].kato);
            // }
        },

        padLeadingZeros(num, size) {    // -------добавление нулей перед значением в зависимости от размера значения
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        concatValues(num, size) { // -------Объединяет значения в одну переменную
            let s = String(num);
            while (s.length < size) { s += s; }
            return s;
        },

        getRowKey(row, keys) {  // ----------
            const elArr = [];
            let key = '';
            for (const k of keys) {
                key = this.concatValues(row[k], 1);
                elArr.push(key);
            }
            return elArr;
        }, // преобразует значения выбранных полей в код

        async getFieldsOfFactAndFin(header) {   // -----Основной метод для извлечения всех справочников для последующего отображения в грид таблице---//
            const yearFact = (header.year - 2).toString();
            const yearPlan = (header.year - 1).toString();
            const year = header.year.toString();
            const obl = this.obl + '0000';   // ------Нужно разобраться с нулями
            // const obl = this.region.toString();
            const region = this.region.toString();

            const abp = this.padLeadingZeros(header.abp, 3);
            const prg = this.padLeadingZeros(header.prg, 3);
            const ppr = this.padLeadingZeros(header.ppr, 3);
            const user = store.state.user.login; // ---Извлекается пользователь---//
            console.log('user: ' + user);
            console.log('obl: ' + obl + ' region: ' + region + ' gu: ' + header.ga + ' abp: ' + abp + ' abp.length: ' + abp.length + ' prg: ' + prg + ' ppr: ' + ppr + ' yearFact: ' + yearFact + ' yearPlan: ' + yearPlan);
            // const abp = '251';
            // const prg = '001';   // ------Нужно разобраться с нулями
            // const ppr = '015';   // ------Нужно разобраться с нулями
            this.year = year;
            this.abp = abp;
            this.prg = prg;
            this.ppr = ppr;
            this.ga = header.ga.toString();
            let listOfFact = [];
            let listOfPlan = [];
            let listOfLimits = [];

            try {
                // ----------Извлечение данных из справочников-----------//
                listOfFact = fetch('/api-py/budget_fact552/' + yearFact + '/' + region + '/' + obl + '/' + abp + '/' + prg + '/' + ppr + '/'); // ---ссылка для отображения "Исполнение за (Прогнозный год-2) год"
                listOfPlan = fetch('/api-py/budget_plan_fin/' + yearPlan + '/' + region + '/' + abp + '/' + prg + '/' + ppr + '/');// ---ссылка для отображения "Уточненный план на (Прогнозный год-1)  г. (без РБ)"
                listOfLimits = fetch('/api-py/budget_form_limits/' + year + '/' + region + '/' + abp + '/' + prg + '/' + ppr + '/');
                Promise.all([listOfFact, listOfPlan, listOfLimits]) // ---метод позволяющий одновременно обращаться через ajax по двум ссылкам
                    .then(values => {
                        return Promise.all(values.map(resp => resp.json()));
                    }).then(async ([listFact, listPlan, listLimits]) => {
                    // console.log('listFact: ' + JSON.stringify(listFact) + ' size: ' + listFact.length);
                    // console.log('listPlan: ' + JSON.stringify(listPlan) + ' size: ' + listPlan.length);
                    // console.log('listLimits: ' + JSON.stringify(listLimits) + ' size: ' + listLimits.length);


                        // ----------Извлекается "индекс инфляции на прогнозный год" для Прогноз на ...(три года)-----//
                        let forecastYearPlusOne = '';
                        let twoYearPlusForecast = '';
                        let threeYearPlusForecast = '';
                        let separateSpecific = '';

                        // this.inflationIndex = '';
                        const inflation = this.inflationIndex;
                        const yearKato = parseInt(this.curHeader.year) + 2;
                        const idVal = inflation[0].dictIndicatorId;
                        const katoVal = inflation[0].kato;
                        // console.log('year: ' + year + ' idVal: ' + idVal + ' kato: ' + katoVal);
                        // const urls = 'https://ea.csi.kz/api/input-forms/data/budget-filter/' + idVal + '?katoCode=' + katoVal + '&year=' + year;

                        const urls = '/api/input-forms/data/budget-filter/' + idVal + '?katoCode=' + katoVal + '&year=' + yearKato;

                        try {
                            await fetch(urls)
                                .then(response => response.json())
                                .then(json => {
                                    separateSpecific = parseFloat(json.value).toFixed(2);
                                    forecastYearPlusOne = parseFloat(json.value).toFixed(2);
                                    twoYearPlusForecast = parseFloat(json.value).toFixed(2);
                                    threeYearPlusForecast = parseFloat(json.value).toFixed(2);
                                // console.log('forecastYearPlusOne: ' + forecastYearPlusOne);
                                });
                        } catch (error) {
                            this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
                        }
                        // -----------------------------------------------------------------------------------//
                        // console.log('listLimits.length: ' + listLimits.length);
                        if (listLimits.length > 0) {    // ---------Если в таблице "budget_form_limits" имеются записи согласно фильтра, то будут браться данные из этой таблицы
                            for (const limit of listLimits) {
                                const spf = limit.spf;
                                const fact = limit.fact_sum;
                                const plan = limit.plan_sum;
                                const oneTimeExpenses = limit.one_time_expenses;
                                const overExpenses = limit.over_expenses;
                                const forecastOneYear = limit.forecast_for_year;
                                const oneForecast = limit.forecast_one;
                                const forecastTwoYear = limit.forecast_for_two_years;
                                const twoForecast = limit.forecast_two;
                                const forecastThreeYear = limit.forecast_for_three_years;
                                const threeForecast = limit.forecast_three;
                                // console.log('forecastYearPlusOne: ' + limit.forecast_for_year + ' oneForecast: ' + limit.forecast_one + ' twoYearPlusForecast: ' + limit.forecast_for_two_years + ' twoForecast: ' + limit.forecast_two + ' threeYearPlusForecast: ' + limit.forecast_for_three_years + ' threeForecast: ' + limit.forecast_three);
                                // console.log('spf_: ' + spf + ' fact_: ' + fact + ' plan_: ' + plan + ' oneTimeExpenses_: ' + oneTimeExpenses + ' expensesInExcessOfParameters_: ' + over_expenses);
                                let title = '';

                                try {
                                // --------Извлекаются сведения из справочника "Наименование"----//
                                    const response = await fetch('/api-py/dict-ebk-spf/' + spf);
                                    const items = await response.json();
                                    for (let i = 0; i < items.length; i++) {
                                        title = items[i].name_ru;
                                    // console.log('title: ' + title);
                                    }
                                } catch (error) {
                                    this.makeToast('danger', 'Ошибка запроса извлечение "Наименования" из  "dict_ebk_ek" ', error.toString());
                                }

                                // -------Объект для отображения данных в грид таблице-------//
                                const valTable = 'limit';
                                const el = this.getElementsByParams(spf, title, fact, plan, oneTimeExpenses, overExpenses, valTable, forecastYearPlusOne, twoYearPlusForecast, threeYearPlusForecast, forecastOneYear, oneForecast, forecastTwoYear, twoForecast, forecastThreeYear, threeForecast, separateSpecific);
                                // console.log('spf: ' + el.spf + ' title: ' + el.title + ' fact: ' + el.fact + ' plan: ' + el.plan + ' forecastYearPlusOne' + el.forecastYearPlusOne);
                                this.budgetFactPlanArr.push(el);    // ----Основной массив для передачи всего массива с объектами в грид таблицу
                            }
                        } else {
                            this.uniqSpf = [];
                            // ------------Извлечения SPF с обеих справочников---------//
                            for (let i = 0; i < listFact.length; i++) {
                                this.uniqSpf.push(listFact[i].spf);
                            }
                            for (let i = 0; i < listPlan.length; i++) {
                                this.uniqSpf.push(listPlan[i].spf);
                            }

                            // -------------Создается массив с уникальными SPF с обеих справочников Fact и Plan----------------//
                            const uniqSpfRes = this.getSpfWithoutDuplicates(this.uniqSpf);

                            // ----Запускается основной цикл для построения единого объекта с последующим отображением в грид таблице----//
                            // console.log('uniqSpfRes.length: ' + uniqSpfRes.length);
                            for (const uspf of uniqSpfRes) {
                            // console.log('budgetFactPlanArr: ' + uniqSpfRes[i]);
                                const spf = uspf;
                                let fact = 0;
                                let plan = 0;
                                let title = '';
                                const oneTimeExpenses = 0;
                                const overExpenses = 0;
                                // ----Извлекается поле sum из справочника Fact----//
                                // console.log('listFact: ' + listFact.length);
                                for (let j = 0; j < listFact.length; j++) {
                                    if (uspf === listFact[j].spf) {
                                        fact = (listFact[j].sum ? listFact[j].sum : 0) / 1000;
                                    // fact = listFact[j].sum ? listFact[j].sum : 0;
                                    }
                                }
                                // ----Извлекается поле sum из справочника Plan----//
                                for (let k = 0; k < listPlan.length; k++) {
                                    if (uspf === listPlan[k].spf) {
                                        plan = listPlan[k].sum != '' ? listPlan[k].sum : 0;
                                    }
                                }
                                try {
                                // --------Извлекаются сведения из справочника "Наименование"----//
                                    const response = await fetch('/api-py/dict-ebk-spf/' + spf);
                                    const items = await response.json();
                                    for (let i = 0; i < items.length; i++) {
                                        title = items[i].name_ru;
                                    }
                                } catch (error) {
                                    this.makeToast('danger', 'Ошибка запроса извлечение "Наименования" из  "dict_ebk_ek" ', error.toString());
                                }
                                // -------Объект для отображения данных в грид таблице-------//
                                const el = this.getElementsByParams(spf, title, fact, plan, oneTimeExpenses, overExpenses, '', forecastYearPlusOne, twoYearPlusForecast, threeYearPlusForecast, '', false, '', false, '', false, separateSpecific);
                                this.budgetFactPlanArr.push(el);    // ----Основной массив для передачи всего массива с объектами в грид таблицу
                            }
                        }
                        const firstGrab = Object.assign([], this.budgetFactPlanArr);
                        // console.log('firstGrab: ' + JSON.stringify(firstGrab));

                        this.grabbingFirstDataIntoMemory(firstGrab);
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getFieldsOfFactAndFin', error.toString());
            }
        },
        // -----Метод для извлечения первичной гриды после фильтра---//
        grabbingFirstDataIntoMemory(firstGrab) {
            // console.log('firstGrab: ' + JSON.stringify(firstGrab));
            this.firstFactPlanMap.clear();
            this.firstFactPlanMapSec.clear();

            for (const row of firstGrab) {
                // console.log('row.spf: ' + row.spf + ' row.oneTimeExpenses: ' + row.oneTimeExpenses + ' row.expensesInExcessOfParameters: ' + row.expensesInExcessOfParameters + ' forecastYearPlusOne: ' + row.forecastYearPlusOne);
                // console.log('map: ' + this.getRowKey(row.spf, ['oneTimeExpenses', 'expensesInExcessOfParameters']) + ' JSON.stringify(row): ' + JSON.stringify(row));
                this.firstFactPlanMap.set(row.spf, JSON.stringify(row));
                this.firstFactPlanMapSec.set(row.spf, JSON.parse(JSON.stringify(row)));    // ----Происходит глубокое копирование объекта
            }
        },
        getElementsByParams(spf, title, fact, plan, oneTimeExpenses, overExpenses, valTable, forecastYearPlusOne, twoYearPlusForecast, threeYearPlusForecast, forecastOneYear, oneForecast, forecastTwoYear, twoForecast, forecastThreeYear, threeForecast, separateSpecific) {
            const specificItems = this.separateSpecific;
            // const specificItems = this.getListOfSpecifics();
            // console.log('specificItems: ' + JSON.stringify(specificItems));

            // ----------------------------------------
            // const specific = this.exclusionOfSpecifics;
            if (oneForecast === true) {
                forecastYearPlusOne = forecastOneYear;
                // console.log('forecastYearPlusOne: ' + forecastYearPlusOne);
            }
            if (twoForecast === true) {
                twoYearPlusForecast = forecastTwoYear;
                // console.log('twoYearPlusForecast: ' + twoYearPlusForecast);
            }
            if (threeForecast === true) {
                threeYearPlusForecast = forecastThreeYear;
                // console.log('threeYearPlusForecast: ' + threeYearPlusForecast);
            }

            let percentGrowthOne = '';
            let percentGrowthTwo = '';
            let percentGrowthThree = '';
            const el = {
                spf: spf,
                title: title,
                factSum: parseFloat(fact).toFixed(2),
                planSum: parseFloat(plan).toFixed(2),
                oneTimeExpenses: parseFloat(oneTimeExpenses).toFixed(2),
                refinedPlan: '',
                forecastYearPlusOne: forecastYearPlusOne,
                forecastOne: oneForecast,
                expensesInExcessOfParameters: overExpenses,
                percentOfGrowth: '',
                twoYearPlusForecast: twoYearPlusForecast,
                forecastTwo: twoForecast,
                twoYearPlusPercent: '',
                threeYearPlusForecast: threeYearPlusForecast,
                forecastThree: threeForecast,
                threeYearPlusPercent: '',
                year: this.year,
                region: this.region,
                abp: this.abp,
                prg: this.prg,
                ppr: this.ppr,
                ga: this.ga,
                separateSpecific: separateSpecific
            };
            // console.log('spf: ' + el.spf + ' factSum: ' + el.factSum + ' planSum: ' + el.planSum + ' separateSpecific: ' + el.separateSpecific);

            if (valTable !== 'limit') {
                this.itemUpdate(el);    // ----Вводимые пустые поля заполняются нулями
            }
            // ---------Вычисление в столбце "Уточненный план на 01.04.2021 г. (без учета разовых расходов)"
            Object.defineProperty(el, 'refinedPlan', {
                get: function () {
                    return ((parseFloat(el.planSum) - parseFloat(el.oneTimeExpenses)).toFixed(2));
                }
            });

            if (oneForecast === false) {
                // ----------Вычисление в столбце "ПРОГНОЗ НА 2022 ГОД"----------------
                el.forecastYearPlusOne = (parseFloat(el.refinedPlan) + (parseFloat(el.refinedPlan) * parseFloat(forecastYearPlusOne)) / parseFloat(100)).toFixed(2);
                // this.$set(el, 'forecastYearPlusOne', parseFloat(el.refinedPlan) + (parseFloat(el.refinedPlan) * parseFloat(forecastYearPlusOne)) / parseFloat(100)).toFixed(2);
                // console.log('oneForecast: ' + oneForecast);
            }
            if (twoForecast === false) {
                // ----------Вычисление в столбце "ПРОГНОЗ 2023 год"
                el.twoYearPlusForecast = (parseFloat(el.forecastYearPlusOne) + (parseFloat(el.forecastYearPlusOne) * parseFloat(twoYearPlusForecast) + parseFloat(1)) / parseFloat(100)).toFixed(2);
                // this.$set(el, 'twoYearPlusForecast', parseFloat(el.forecastYearPlusOne) + (parseFloat(el.forecastYearPlusOne) * parseFloat(twoYearPlusForecast) + parseFloat(1)) / parseFloat(100)).toFixed(2);
                // console.log('twoForecast: ' + twoForecast);
            }
            if (threeForecast === false) {
                // ----------Вычисление в столбце "ПРОГНОЗ 2024 год"
                el.threeYearPlusForecast = (parseFloat(el.twoYearPlusForecast) + (parseFloat(el.twoYearPlusForecast) * parseFloat(threeYearPlusForecast) + parseFloat(2)) / parseFloat(100)).toFixed(2);
                // this.$set(el, 'threeYearPlusForecast', parseFloat(el.twoYearPlusForecast) + (parseFloat(el.twoYearPlusForecast) * parseFloat(threeYearPlusForecast) + parseFloat(2)) / parseFloat(100)).toFixed(2);
                // console.log('threeForecast: ' + threeForecast);
            }

            // ---------Вычисление в столбце "% роста"
            Object.defineProperty(el, 'percentOfGrowth', {
                get: function () {
                    percentGrowthOne = parseFloat((el.forecastYearPlusOne / el.refinedPlan) * 100).toFixed(2);
                    return (isNaN(percentGrowthOne) || !isFinite(percentGrowthOne) ? parseFloat(0).toFixed(2) : percentGrowthOne);
                }
            });
            // ---------Вычисление в столбце "% роста - 2023 год"
            Object.defineProperty(el, 'twoYearPlusPercent', {
                get: function () {
                    percentGrowthTwo = parseFloat((el.twoYearPlusForecast / el.forecastYearPlusOne) * 100).toFixed(2);
                    return (isNaN(percentGrowthTwo) || !isFinite(percentGrowthTwo) ? parseFloat(0).toFixed(2) : percentGrowthTwo);
                }
            });
            // // ---------Вычисление в столбце "% роста - 2024 год"
            Object.defineProperty(el, 'threeYearPlusPercent', {
                get: function () {
                    percentGrowthThree = parseFloat((el.threeYearPlusForecast / el.twoYearPlusForecast) * 100).toFixed(2);
                    // console.log('percentGrowthThree: ' + percentGrowthThree);
                    return (isNaN(percentGrowthThree) || !isFinite(percentGrowthThree) ? parseFloat(0).toFixed(2) : percentGrowthThree);
                }
            });


            // ---------------Извлекается отдельная специфика с исключениями------------------------------------------//
            const year = parseInt(this.curHeader.year);
            // console.log('year: ' + year);
            // console.log('spf: ' + spf + 'specificItems: ' + JSON.stringify(specificItems));
            for (let i = 0; i < specificItems.length; i++) {
                if (spf == specificItems[i].spf) {
                    // console.log('specificItems[i].spf: ' + specificItems[i].spf);
                    const urls = '/api/input-forms/data/budget-filter/' + specificItems[i].dictIndicatorId + '?katoCode=' + specificItems[i].kato + '&year=' + year;
                    fetch(urls, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => { return response.json(); })
                        .then(resVal => {
                            el.separateSpecific = resVal.value;
                            // console.log('el.separateSpecific: ' + el.separateSpecific);
                            // console.log(' result_value: ' + resVal.value);
                            // this.$set(el, 'forecastYearPlusOne', parseFloat(el.refinedPlan) + (parseFloat(el.refinedPlan) * parseFloat(resVal.value)) / parseFloat(100)).toFixed(2);
                            // this.$set(el, 'twoYearPlusForecast', parseFloat(el.refinedPlan) + (parseFloat(el.refinedPlan) * parseFloat(resVal.value) + parseFloat(1)) / parseFloat(100)).toFixed(2);
                            // this.$set(el, 'threeYearPlusForecast', parseFloat(el.refinedPlan) + (parseFloat(el.refinedPlan) * parseFloat(resVal.value) + parseFloat(2)) / parseFloat(100)).toFixed(2);
                            if (oneForecast === false) {
                                // ----------Вычисление в столбце "ПРОГНОЗ НА 2022 ГОД"----------------
                                el.forecastYearPlusOne = (parseFloat(el.refinedPlan) + (parseFloat(el.refinedPlan) * parseFloat(resVal.value)) / parseFloat(100)).toFixed(2);
                            }
                            if (twoForecast === false) {
                                // ----------Вычисление в столбце "ПРОГНОЗ 2023 год"
                                el.twoYearPlusForecast = (parseFloat(el.forecastYearPlusOne) + (parseFloat(el.forecastYearPlusOne) * parseFloat(resVal.value) + parseFloat(1)) / parseFloat(100)).toFixed(2);
                            }
                            if (threeForecast === false) {
                                // ----------Вычисление в столбце "ПРОГНОЗ 2024 год"
                                el.threeYearPlusForecast = (parseFloat(el.twoYearPlusForecast) + (parseFloat(el.twoYearPlusForecast) * parseFloat(resVal.value) + parseFloat(2)) / parseFloat(100)).toFixed(2);
                            }
                            return resVal;
                        })
                        .catch(err => {
                            console.log('fetch error' + err);
                        });
                }
            }
            return el;
        },
        changeCellForecast(item, forecastYearPlusOne) {
            item.forecastYearPlusOne = parseFloat(forecastYearPlusOne).toFixed(2);
            // console.log('separateSpecific: ' + item.separateSpecific);
            item.twoYearPlusForecast = (parseFloat(forecastYearPlusOne) + (parseFloat(forecastYearPlusOne) * parseFloat(item.separateSpecific) + parseFloat(1)) / parseFloat(100)).toFixed(2);
            item.threeYearPlusForecast = (parseFloat(item.twoYearPlusForecast) + (parseFloat(item.twoYearPlusForecast) * parseFloat(item.separateSpecific) + parseFloat(2)) / parseFloat(100)).toFixed(2);
        },
        changeCellForecastTwo(item, twoYearPlusForecast) {
            item.twoYearPlusForecast = parseFloat(twoYearPlusForecast).toFixed(2);
            item.threeYearPlusForecast = (parseFloat(twoYearPlusForecast) + (parseFloat(twoYearPlusForecast) * parseFloat(item.separateSpecific) + parseFloat(2)) / parseFloat(100)).toFixed(2);
        },
        getSpfWithoutDuplicates(arr) {  // -----Функция для очистки массива от дубликатов
            const uniqueArray = arr.filter(function (item, pos) {
                return arr.indexOf(item) === pos;
            });
            return uniqueArray;
        },
        // -------Метод для извлечения области и региона-------//
        async getObl() {    // -----Извлекается регион и область из глобальных настроек пользователя
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.region = json.region;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        // -------Метод для сравнение данных в гриде на наличие не сохраненных данных---//
        comparingData() {
            const saveDataIntoDB = [];
            // ----------Проверка--------------//
            const factPlan = this.budgetFactPlanArr;
            // console.log('factPlan: ' + JSON.stringify(factPlan));

            for (const row of factPlan) {
                const vials = this.firstFactPlanMap.get(row.spf);   // ----Объект, который определяет, что имеются изменения в объекте в сравнении с первичным объектом

                if (JSON.stringify(row) !== vials) {
                    // console.log('vials: ' + JSON.stringify(vials) + '\n row: ' + JSON.stringify(row));
                    const underItem = this.firstFactPlanMapSec.get(row.spf);    // ----Объект позволяет извлекать данные из внутреннего объекта

                    if (parseFloat(row.forecastYearPlusOne) !== parseFloat(underItem.forecastYearPlusOne)) {
                        row.forecastOne = true;
                        // console.log('forecastYearPlusOne: ' + true + ' row.forecastYearPlusOne: ' + row.forecastYearPlusOne + ' underItem.forecastYearPlusOne: ' + underItem.forecastYearPlusOne);
                    }
                    if (parseFloat(row.twoYearPlusForecast) !== parseFloat(underItem.twoYearPlusForecast)) {
                        row.forecastTwo = true;
                        // console.log('twoYearPlusForecast: ' + true);
                    }
                    if (parseFloat(row.threeYearPlusForecast) !== parseFloat(underItem.threeYearPlusForecast)) {
                        row.forecastThree = true;
                        // console.log('threeYearPlusForecast: ' + true);
                    }
                    // console.log('row.forecastYearPlusOne: ' + row.forecastYearPlusOne + ' spf: ' + underItem.spf + ' forecastYearPlusOne: ' + underItem.forecastYearPlusOne + ' twoYearPlusForecast: ' + underItem.twoYearPlusForecast + ' threeYearPlusForecast: ' + underItem.threeYearPlusForecast);
                    // ---------------------
                    const toDB = row;
                    saveDataIntoDB.push(toDB);
                    this.leavePage = true;
                }
            }
            // console.log('saveDataIntoDB: ' + JSON.stringify(saveDataIntoDB));
            return saveDataIntoDB;
        },

        // -------Метод для отображения диалогового окна для сохранения данных в БД----//
        saveDataToDB() {
            const res = this.comparingData();
            // console.log('res: ' + res.length);
            // if (res.length > 0) {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите сохранить данные?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.btnForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка сохранения', error.toString());
                });
            // }
        },

        async btnForSave() {
            // ----------------------------------------------------------//
            try {
                // if (res.length > 0) {
                // console.log('item for save: ' + JSON.stringify(listOfLimitParams));
                // console.log('year: ' + this.year + ' region: ' + this.region + ' abp: ' + this.abp + ' prg: ' + this.prg + '  ppr: ' + this.ppr + ' ga: ' + this.ga);
                // ---------------------------------------------------------------//
                const listOfLimitParams = this.budgetFactPlanArr;
                // console.log('listOfLimitParams: ' + JSON.stringify(listOfLimitParams));
                const url = '/api-py/budget-form-limit-save/';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(listOfLimitParams)
                });
                const result = await response.json();
                if ((response.status == 200) && (result.result == 'success')) {
                    this.leavePage = false;
                    this.firstFactPlanMap.clear();
                    this.firstFactPlanMapSec.clear();

                    for (const limit of listOfLimitParams) {
                        this.firstFactPlanMap.set(limit.spf, JSON.stringify(limit));
                        this.firstFactPlanMapSec.set(limit.spf, JSON.parse(JSON.stringify(limit)));
                    }

                    // console.log('this.firstFactPlanMap: ' + JSON.stringify(this.firstFactPlanMap));

                    this.makeToast('Сообщение', 'Данные сохранены', '');
                }
                // }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения данных');
            }
        },
        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение с ошибкой
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            console.log('currentNode: ' + currentNode);
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            console.log('allElements: ' + allElements);
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            console.log('currentIndex: ' + currentIndex);
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            console.log('targetIndex: ' + targetIndex);
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
            console.log('allElements: ' + allElements);
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            // console.log('regex: ' + regex);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            // console.log('key: ' + key);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        itemUpdate(item) {  // -----В полях ввода устанавливаются 0 - нули по умолчанию
            this.$set(item, 'oneTimeExpenses', 0.00);
            this.$set(item, 'expensesInExcessOfParameters', 0.00);
        },

        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        } // форматирует введенное значение до digit цифр после запятой

    },
    beforeUpdate() {
        const currentYear = this.curHeader.year;
        // const currentYearPlus = this.curHeader.year;
        const execYear = parseInt(currentYear) - 2;
        const planYear = parseInt(currentYear) - 1;
        const forecastOne = parseInt(currentYear);
        const growthOne = parseInt(currentYear);
        const forecastTwo = parseInt(currentYear) + 1;
        const forecastThree = parseInt(currentYear) + 2;
        const growthTwo = parseInt(currentYear) + 1;
        const growthThree = parseInt(currentYear) + 2;

        this.tableFields[2].label = 'ИСПОЛНЕНИЕ ЗА ' + execYear + ' ГОД';
        this.tableFields[3].label = 'УТОЧНЕННЫЙ ПЛАН НА ' + planYear + ' Г. (БЕЗ РБ)';
        this.tableFields[5].label = 'УТОЧНЕННЫЙ ПЛАН НА ПРОГНОЗНЫЙ ' + currentYear + ' ГОД (БЕЗ УЧЕТА РАЗОВЫХ РАСХОДОВ)';
        this.tableFields[6].label = 'ПРОГНОЗ НА ' + forecastOne;
        this.tableFields[8].label = '% роста ' + growthOne;
        this.tableFields[9].label = 'ПРОГНОЗ НА ' + forecastTwo + ' год';
        this.tableFields[10].label = '% роста ' + growthTwo + ' год';
        this.tableFields[11].label = 'ПРОГНОЗ НА ' + forecastThree + ' год';
        this.tableFields[12].label = '% роста ' + growthThree + ' год';
    },
    created() {
        // this.$store.commit('loadListOfLimits', this.budgetFactPlanArr);
    },
    computed: {

        // budgetFactPlanArr() {
        //     return this.$store.state.limits.budgetFactPlanArr;
        //     // return this.$store.getters.getBudgetFactPlanArr;
        // },

        // ----ИСПОЛНЕНИЕ ЗА (ПРОГНОЗНЫЙ ГОД-2) ГОД
        factSum() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.factSum !== '' ? row.factSum : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----УТОЧНЕННЫЙ ПЛАН НА (ПРОГНОЗНЫЙ ГОД-1) Г. (БЕЗ РБ)
        planSum() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.planSum !== '' ? row.planSum : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----РАЗОВЫЕ РАСХОДЫ
        oneTimeExpenses() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.oneTimeExpenses !== '' ? row.oneTimeExpenses : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----УТОЧНЕННЫЙ ПЛАН НА 01.04.2021 Г. (БЕЗ УЧЕТА РАЗОВЫХ РАСХОДОВ)
        refinedPlan() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.refinedPlan !== '' ? row.refinedPlan : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----ПРОГНОЗ НА 2022 ГОД
        forecastYearPlusOne() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.forecastYearPlusOne !== '' ? row.forecastYearPlusOne : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ
        expensesInExcessOfParameters() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.expensesInExcessOfParameters !== '' ? row.expensesInExcessOfParameters : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----% РОСТА
        percentOfGrowth() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.percentOfGrowth !== '' ? row.percentOfGrowth : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // -----ПРОГНОЗ 2023 ГОД
        twoYearPlusForecast() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.twoYearPlusForecast !== '' ? row.twoYearPlusForecast : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----ПРОГНОЗ 2024 ГОД
        threeYearPlusForecast() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.threeYearPlusForecast !== '' ? row.threeYearPlusForecast : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----% РОСТА 2023 ГОД
        twoYearPlusPercent() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.twoYearPlusPercent !== '' ? row.twoYearPlusPercent : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        },
        // ----% РОСТА 2024 ГОД
        threeYearPlusPercent() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.threeYearPlusPercent !== '' ? row.threeYearPlusPercent : 0.00);
            }
            return parseFloat(sum).toFixed(2);
        }
    }
};
</script>

<style scoped>

</style>